footer {
  background: $black;
  color: white;
  border-top: 1px solid $dark-gray;
  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1100px;
    margin: auto;
    .footer-section {
      margin-bottom: 25px;
      width: 50%;

      p {
        margin-bottom: 5px;
        a {
          color: $white;
          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .copyright {
        margin-bottom: 15px;
        display: block;
      }
      .social {
        margin-top: 20px;
        .social-icons {
          display: flex;
          align-items: center;
          .social-link {
            margin-right: 10px;
            width: 20px;
            height: auto;
            margin-bottom: 0;
            .social-icon {
              width: 100%;
              height: auto;
            }
          }
        }
      }
      a {
        display: block;
        margin-bottom: 10px;
        text-decoration: none;
        font-weight: normal;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &:first-of-type,
      &:last-of-type {
        width: 100%;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  footer {
    .wrapper {
      flex-wrap: wrap;
      justify-content: space-between;
      .footer-section {
        margin-right: 20px;
        width: auto;
        order: 1;
        p {
          margin-top: 5px;
        }
        .social {
          margin: 0;
        }
        .copyright {
          margin-top: 0;
        }
        &:last-of-type {
          flex-basis: 100%;
          order: 3;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  footer {
    .wrapper {
      .footer-section {
        p {
          margin: 0;
        }
      }
    }
  }
}
