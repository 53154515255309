@font-face {
  font-family: 'Gotham Book Regular';
  src: url("../fonts/Gotham Book Regular.otf") format("opentype");
}

@font-face {
  font-family: 'Gotham Bold';
  src: url("../fonts/Gotham-Bold.otf") format("opentype");
}

@font-face {
  font-family: 'Gotham Book';
  src: url("../fonts/Gotham-Book.otf") format("opentype");
}

@font-face {
  font-family: 'Gotham Light';
  src: url("../fonts/Gotham-Light.otf") format("opentype");
}

@font-face {
  font-family: 'Gotham Medium';
  src: url("../fonts/Gotham-Medium.otf") format("opentype");
}

.fine-print {
  font-size: 12px !important;
  font-family: Gotham-Book, sans-serif;
  color: #707070;
  line-height: 25px;
}

.fine-print a {
  color: #707070;
  text-decoration: underline;
}

.font-bold {
  font-family: 'Gotham Bold', sans-serif;
}

.font-thin {
  font-family: Gotham-Light, sans-serif;
  font-weight: 100;
}

.font-normal {
  font-family: 'Gotham Book', sans-serif;
}

.line-space {
  letter-spacing: 1.1px;
}

.letter-space-2 {
  letter-spacing: 2.5px;
}

.caslon {
  font-family: adobe-caslon-pro, serif;
  font-weight: 600;
  font-style: normal;
}

.caslon-it {
  font-family: adobe-caslon-pro, serif;
  font-weight: 600;
  font-style: italic;
}

.cs-info {
  font-size: 0.7rem;
}

h1:not(.font-normal),
h2:not(.font-normal) {
  font-size: 2.1rem;
  font-family: adobe-caslon-pro, serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
}

#modal-title {
  font-family: 'Gotham Bold', sans-serif;
}

p {
  line-height: 23px;
}

.name h3 {
  font-size: 0.625rem;
}

.name h4 {
  font-size: 0.75rem;
}

.detail-heading {
  font-family: Gotham-Light, sans-serif;
  font-weight: 100;
  letter-spacing: 2.65px;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.img-details span {
  font-size: 0.9rem;
}

.img-details h1 {
  font-size: 1.1rem;
}

.store-information .store-name,
.info-window-content .store-name {
  font-family: 'Gotham Bold', sans-serif;
}

.footer-section h2 {
  font-family: 'Gotham Bold', serif;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
  letter-spacing: 1.1px;
}

.footer-section .cresco {
  letter-spacing: 3.5px;
}

.footer-section p {
  letter-spacing: 1.1px;
  font-size: 0.8rem;
  margin-top: 10px;
}

.faq-module-wrapper h2 {
  font-size: 1.2rem;
}

.faq-page {
  padding-bottom: 90px;
}

.faq-page h2 {
  font-size: 1.4rem;
}

@media only screen and (min-width: 768px) {
  .name h3 {
    font-size: 0.85rem;
  }
  .name h4 {
    font-size: 1rem;
  }
  .cs-info {
    font-size: 1rem;
  }
  .copywrapper.altHeadingCopy h1 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 900px) {
  h1:not(.font-normal) {
    font-size: 3.4rem;
  }
  h2:not(.font-normal) {
    font-size: 2.8rem;
  }
  p {
    font-size: 1.1rem;
    line-height: 26px;
  }
  .footer-section h2 {
    font-size: 1rem;
  }
  .faq-module-wrapper h2 {
    font-size: 1.3rem;
  }
  .faq-page h2 {
    font-size: 1.6rem;
  }
}

@media only screen and (min-width: 1200px) {
  .detail-heading {
    font-size: 1.1rem;
  }
  .strain-info-item {
    font-size: 1.1rem;
  }
  .img-details span {
    font-size: 1.25rem;
    display: block;
    margin-bottom: 8px;
  }
  .img-details h1 {
    font-size: 1.5rem;
  }
}

.padding-lr {
  padding: 0 40px;
}

.padding-all {
  padding: 40px;
}

.padding-noTop {
  padding: 0px 40px 40px 40px;
}

.padding-noBot {
  padding: 40px 40px 0 40px;
}

.padding-10 {
  padding: 10px;
}

.padding-all-30 {
  padding: 30px;
}

.margin-all-15 {
  margin: 15px;
}

.margin-all-30 {
  margin: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.m-30-auto {
  margin: 30px auto 20px;
}

figure {
  margin: 0;
  padding: 0;
}

.center,
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex-row-spaced {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.flex-row-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.flex-row-start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.shrink-0 {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.justify-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.justify-spaced {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.justify-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.justify-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.justify-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.flex-row-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.flex-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.flex-col-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.align-start {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.align-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.align-end {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.align-self-top {
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

ul {
  margin: 0;
  padding-left: 0;
}

ul li {
  text-decoration: none;
  list-style: none;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.upper {
  text-transform: uppercase;
}

.lower {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.center-xy {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.center-x {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.center-y {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.invert {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.border-radius-small {
  border-radius: 4px;
}

.border-radius-medium {
  border-radius: 8px;
}

.border-radius-large {
  border-radius: 16px;
}

.nowrap {
  white-space: nowrap;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.width-700 {
  max-width: 700px;
}

.width-480 {
  max-width: 480px;
  margin: auto;
}

.width-1100 {
  max-width: 1100px;
  margin: auto;
}

.width-50 {
  width: 50%;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.line-through {
  text-decoration: line-through;
}

.grow-1 {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.inline {
  display: inline;
}

strong,
b {
  font-weight: bold;
}

.font-medium.bold,
.font-medium > strong {
  font-family: 'Gotham Bold', sans-serif;
  letter-spacing: 0;
}

.font-book {
  font-family: 'Gotham Book', sans-serif;
  letter-spacing: -0.04rem;
}

.font-book.bold,
.font-book > strong {
  font-family: 'Gotham Medium', sans-serif;
  letter-spacing: 0;
}

.opacity-0 {
  opacity: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.preline {
  white-space: pre-line;
}

.first-child-m0:first-child {
  margin: 0;
}

.fill-inherit {
  fill: inherit;
}

.fill-inherit path {
  fill: inherit;
}

.disabled-opacity {
  opacity: 0.4;
}

.m-0-auto {
  margin: 0 auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-30 {
  margin-top: 30px;
}

div.bg-white-hover:hover,
button.bg-white-hover:hover {
  background-color: #efefef;
}

.no-text-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
}

.hide-scrollbars {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbars::-webkit-scrollbar {
  display: none;
}

.opacity-hover:hover {
  opacity: 0.75;
}

.dot-icon {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.overflow-visible {
  overflow: visible;
}

/* Remove the clear icon on seach inputs */
input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='text']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='text']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.screen-reader-only {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  overflow: hidden !important;
}

.contain-img {
  display: block;
  max-width: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.cover-img {
  display: block;
  max-width: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.no-padding-left {
  padding-left: 0;
}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin: auto;
}

.inherit-height {
  height: inherit;
}

.no-line-break {
  white-space: nowrap;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-25 {
  margin-left: 25px;
}

.mr-20 {
  margin-right: 20px;
}

.bord-bott {
  border-bottom: 1px solid #000000;
}

.bord-bott-white {
  border-bottom: 1px solid #ffffff;
}

.bord-bott-gray {
  border-bottom: 1px solid #707070;
}

.bord-top-bottom {
  border-bottom: 1px solid #3e3e3e;
  border-top: 1px solid #3e3e3e;
}

.bord-bott-all {
  border: 1px solid #707070;
}

.black-background {
  background: #000000;
  color: #ffffff;
}

.copy-wrapper {
  max-width: 800px;
}

.ada-switch .MuiSwitch-track {
  background-color: #f5f5f5 !important;
}

.ada-switch .Mui-checked .MuiIconButton-label {
  color: #c47200;
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.width-100 {
  width: 100%;
}

.button-width-style {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.nowrap {
  white-space: nowrap;
}

#age-gate {
  height: 100%;
}

#age-gate .age-gate-content {
  height: 100%;
  padding: 0 80px;
  max-width: 500px;
  margin: auto;
}

#age-gate .agegate-logo {
  max-width: 400px;
  margin: auto;
  position: relative;
  bottom: 60px;
}

#age-gate .spanish-toggle {
  padding: 15px;
}

#age-gate #age-gate-form {
  padding: 25px 0;
}

#age-gate #age-gate-form button:first-child {
  margin-right: 30px;
}

.ada-reorder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.ada-reorder #age-gate-form {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}

#modal-title {
  font-size: 22px;
  text-transform: uppercase;
}

.shop-page-dialog .faq-popover .MuiPopover-paper {
  bottom: 20px;
  right: 20px;
}

.shop-page-dialog .MuiPaper-rounded {
  border-radius: 0px !important;
}

.shop-page-dialog .MuiDialog-paperWidthSm {
  max-width: 850px !important;
}

.shop-page-dialog .MuiBackdrop-root {
  background: #000000 !important;
}

.dialog-wrapper {
  position: relative;
  text-align: center;
  padding: 20px;
  text-align: center;
  background: #000000;
  color: white;
}

.dialog-wrapper .close {
  position: absolute;
  bottom: 30px;
  right: 20px;
}

.dialog-wrapper .button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 30px;
}

.dialog-wrapper .button-container .sunny-logo,
.dialog-wrapper .button-container .jane-logo {
  margin: 0 auto 25px;
  display: block;
  border-radius: 10px;
}

.dialog-wrapper .button-container .sunny-logo {
  width: 150px;
}

.dialog-wrapper .button-container .jane-logo {
  width: 86px;
}

.dialog-wrapper .button-container .shop-sunny {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding-bottom: 35px;
  margin-bottom: 32px;
}

.dialog-wrapper .button-container .shop-sunny button {
  padding: 7px 25px;
}

.dialog-wrapper .button-container a {
  text-decoration: none;
}

.dialog-wrapper .button-container a button {
  font-size: 12px;
  background: none;
  color: white;
  background: #c47200;
  -webkit-transition: 200ms ease;
  transition: 200ms ease;
}

.dialog-wrapper .logo-container {
  margin-bottom: 0px;
  padding: 20px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.dialog-wrapper .logo-container svg,
.dialog-wrapper .logo-container img {
  max-width: 140px;
  margin: auto;
}

.dialog-wrapper .logo-container svg path,
.dialog-wrapper .logo-container img path {
  fill: white;
}

.dialog-wrapper .content {
  margin: 40px auto 10px;
  max-width: 450px;
}

.dialog-wrapper .content p {
  font-size: 16px;
  color: white;
}

.dialog-wrapper .disclaimer-text p {
  font-size: 12px;
  line-height: 16px;
  max-width: 500px;
  margin: 35px auto 0 auto;
  color: white;
}

.dialog-wrapper .disclaimer-text p a {
  color: white;
  text-decoration: underline;
}

@media only screen and (min-width: 900px) {
  .dialog-wrapper .button-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-top: 30px;
  }
  .dialog-wrapper .button-container .sunny-logo,
  .dialog-wrapper .button-container .jane-logo {
    display: block;
  }
  .dialog-wrapper .button-container .sunny-logo {
    width: 198px;
    margin-bottom: 35px;
  }
  .dialog-wrapper .button-container .jane-logo {
    width: 104px;
  }
  .dialog-wrapper .button-container .shop-sunny {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
    padding: 15px 35px 0 35px;
  }
  .dialog-wrapper .button-container .shop-sunny button {
    padding: 0 25px;
  }
  .dialog-wrapper .button-container .shop-jane {
    padding: 15px 35px 0 35px;
  }
  .dialog-wrapper .button-container a button {
    font-size: 16px;
  }
  .dialog-wrapper .logo-container {
    margin-bottom: 20px;
  }
  .dialog-wrapper .logo-container svg {
    max-width: 200px;
  }
  .dialog-wrapper .content {
    margin-bottom: 50px;
    margin-top: 55px;
  }
  .dialog-wrapper .content p {
    font-size: 20px;
  }
  .dialog-wrapper .disclaimer-text {
    margin-top: 50px;
  }
}

html,
body {
  height: 100%;
}

html.whiteBackground,
body.whiteBackground {
  background: white !important;
  color: black !important;
}

html.blackBackground,
body.blackBackground {
  background: black;
  color: white;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Gotham Book', sans-serif;
}

#app-body {
  min-height: 100vh;
}

.loading-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: #000000;
  top: 0;
  left: 0;
}

.loading-screen .circ-progress {
  color: #c47200;
}

nav {
  background: #000000;
  min-height: 60px;
  position: fixed;
  width: 90%;
  margin: auto;
  top: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 50px;
  border: 1px solid #3e3e3e;
  z-index: 4;
}

nav .mobile-bar-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 60px;
}

nav ul {
  display: none;
  text-align: center;
}

nav ul a {
  text-transform: uppercase;
  color: #ffffff;
  display: block;
  padding: 7px;
}

nav ul li:last-of-type {
  padding-bottom: 18px;
}

nav ul.openNav {
  display: block;
}

nav #nav-logo img {
  width: 140px;
}

#simple-menu a {
  color: #ffffff;
  font-family: 'Gotham Book', sans-serif;
}

@media only screen and (min-width: 768px) {
  nav {
    top: 0;
    width: 100%;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #3e3e3e;
  }
  nav .nav-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    max-width: 1550px;
    margin: auto;
  }
  nav ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  nav ul li {
    margin-right: 25px;
  }
  nav ul li:last-of-type {
    padding-bottom: 0;
  }
}

.full-background-hero {
  width: 100%;
  position: relative;
  width: 100%;
  height: 85vh;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 30px auto 30px;
      grid-template-columns: 30px auto 30px;
  -ms-grid-rows: 190px minmax(190px, auto) auto;
      grid-template-rows: 190px minmax(190px, auto) auto;
  overflow: hidden;
}

.full-background-hero .hero-fig {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  grid-row: 1 / 4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-column: 1 / 4;
  height: inherit;
}

.full-background-hero .hero-fig img {
  height: inherit;
}

.full-background-hero .container {
  max-width: 1400px;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
}

.full-background-hero .copy-wrapper {
  text-align: center;
  max-width: 600px;
}

.standard-hero {
  padding-top: 90px;
}

.standard-hero .container {
  max-width: 1088px;
  margin: auto;
}

.standard-hero figure img {
  height: 100%;
  max-height: 450px;
}

.standard-hero .copywrapper.altHeadingCopy h1 {
  font-family: 'Gotham book', sans-serif;
  max-width: 100%;
  margin: 20px auto 0;
}

.standard-hero .copywrapper.altHeadingCopy h1:after {
  background: none;
  height: 0;
}

.full-background-hero-v2 {
  background: #000000;
  color: #ffffff;
  padding-top: 120px;
}

.full-background-hero-v2 .copy-wrapper {
  padding: 40px 40px 30px 40px;
  margin: auto;
}

@media only screen and (min-width: 768px) {
  .full-background-hero {
    margin-top: 60px;
    -ms-grid-columns: 60px auto 60px;
        grid-template-columns: 60px auto 60px;
  }
  .full-background-hero .copy-wrapper {
    text-align: left;
  }
  .standard-hero {
    padding-top: 60px;
  }
  .standard-hero .copywrapper.altHeadingCopy h1 {
    margin: 50px auto 0;
  }
  .full-background-hero-v2 {
    padding-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: auto;
  }
  .full-background-hero-v2 .hero-fig,
  .full-background-hero-v2 .cover-img {
    max-height: 1000px;
  }
  .full-background-hero-v2 .copy-wrapper {
    padding: 80px 40px;
  }
}

.squareContainer {
  position: relative;
}

.perfectSquare {
  position: relative;
  width: 50%;
}

.perfectSquare:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.squareContent {
  position: absolute;
  width: 100%;
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 30% 70%;
      grid-template-rows: 30% 70%;
}

.squareContent .name {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / 2;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 10px;
}

.squareContent .squareImg {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
}

.squareHover {
  background-color: #000000;
  border: 1px solid #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 50ms ease;
  transition: opacity 50ms ease;
}

.squareHover .sqHoverImg {
  opacity: 0;
  -webkit-transition: all 200 ease;
  transition: all 200 ease;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
}

@media only screen and (min-width: 768px) {
  .squareContent {
    -ms-grid-rows: 65px calc(100% - 65px);
        grid-template-rows: 65px calc(100% - 65px);
  }
}

@media only screen and (min-width: 1200px) {
  .squareHover:hover, .squareHover:focus {
    opacity: 1;
  }
  .squareHover:hover .sqHoverImg {
    opacity: 1;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

#contact-page {
  margin-top: 100px;
}

#contact-page .wrapper {
  max-width: 700px;
  margin: auto;
  margin-bottom: 40px;
}

#contact-header {
  text-align: center;
}

#contact-header h1 {
  text-align: center;
  padding: 40px 0px;
  margin: 0;
}

.form-message {
  display: block;
  background: #000000;
  color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.form-message a {
  color: white;
  text-decoration: underline;
}

.form-message.error {
  color: #ad0000;
  background: none;
  border: 2px solid #ad0000;
}

.form-message.success .brandColor {
  font-weight: bold;
}

.tooltipButton {
  margin: 0;
  padding: 0;
  height: 18px;
  width: 18px;
  background: none;
  border: none;
  cursor: pointer;
}

.faq-wrapper {
  margin: 15px 0 0 15px;
}

.answer p {
  margin: 0;
}

.faq-error h3 {
  margin-bottom: 0;
}

.faq-error a {
  color: #000000;
  text-decoration: underline;
  font-weight: bold;
}

#menu-select-strain .MuiMenuItem-root,
#menu-select-strain .MuiMenu-list {
  background-color: #ffffff !important;
  color: #000000 !important;
}

#menu-select-strain .MuiListItem-root {
  background-color: #ffffff !important;
  color: #000000 !important;
}

#menu-select-strain .MuiListItem-root:hover {
  background-color: #f5f5f5 !important;
}

.white-select .MuiOutlinedInput-notchedOutline {
  border: 2px solid #ffffff !important;
}

.white-select .MuiOutlinedInput-notchedOutline:hover {
  border: 2px solid #ffffff !important;
}

.white-select .MuiSelect-nativeInput,
.white-select .MuiSelect-root,
.white-select .MuiFormLabel-root {
  color: #ffffff !important;
}

.white-select .MuiSelect-icon {
  fill: #ffffff !important;
}

#jane-menu {
  margin-top: 130px;
}

.content-slice {
  margin-top: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.content-slice h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.content-slice a {
  color: #000000;
  text-decoration: underline;
}

.opp-blocks {
  max-width: 1400px;
  margin: auto;
}

.opp-blocks img {
  width: 300px;
  height: 300px;
}

.opp-blocks .container {
  max-width: 650px;
}

.opp-blocks .copy-wrapper h2,
.opp-blocks .copy-wrapper h3 {
  margin: 30px auto 20px;
}

.three-img-section,
.img-grid-container {
  padding: 20px 0 5px 0;
  border-bottom: 1px solid #3e3e3e;
  text-align: center;
}

.three-img-section .container,
.img-grid-container .container {
  padding-top: 2rem;
}

.three-img-section .three-col-grid,
.img-grid-container .three-col-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(250px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;
  max-width: 1250px;
  margin: auto;
  padding-top: 20px;
}

.three-img-section .img-grid,
.img-grid-container .img-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(250px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;
  max-width: 1800px;
  margin: auto;
  padding-top: 20px;
}

.three-img-section .img-grid .link-hover,
.img-grid-container .img-grid .link-hover {
  -webkit-transition: 500ms ease-out;
  transition: 500ms ease-out;
  opacity: 1;
}

.three-img-section .img-grid .link-hover:hover,
.img-grid-container .img-grid .link-hover:hover {
  opacity: 0.8;
}

.three-img-section .img-grid h3,
.img-grid-container .img-grid h3 {
  color: white;
}

.resp-grid-4col {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(140px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-gap: 0.5rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: 1400px;
  margin: auto;
}

.strains {
  padding-bottom: 55px;
}

.cs-copy {
  text-transform: capitalize;
}

.cs-details {
  width: 100%;
  height: 180px;
}

.cs-details p {
  margin: 10px 0 0 0;
}

.cs-details .cs-details-img {
  width: 85px;
}

.cs-info p {
  margin-top: 5px;
  font-size: 0.8rem;
}

.cs-info .cs-info-img {
  width: 60px;
}

.img-content {
  height: 100%;
}

.strain-info {
  color: #ffffff;
  border: 1px solid #707070;
  margin: 0;
  width: 100%;
}

.strain-info h3,
.strain-info h4 {
  margin: 0;
}

.nam.lineagee h3 {
  margin-bottom: 3px;
}

.strain-details {
  position: relative;
  padding-top: 120px;
  padding-bottom: 70px;
  background: #000000;
  color: #ffffff;
}

.strain-details h1 {
  margin: 0;
}

.strain-details .strain-wrapper {
  padding: 20px;
  max-width: 1350px;
  margin: auto;
}

.strain-details .details {
  border: 1px solid #707070;
}

.strain-details .img-details {
  border-top: 1px solid #707070;
  border-right: 1px solid #707070;
  border-left: 1px solid #707070;
}

.strain-details .strain-cta {
  border-bottom: 1px solid #707070;
  border-right: 1px solid #707070;
  border-left: 1px solid #707070;
}

.strain-details .details {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 45% 55%;
      grid-template-columns: 45% 55%;
  -ms-grid-rows: (auto)[5];
      grid-template-rows: repeat(5, auto);
  font-size: 0.8rem;
  text-align: center;
}

.strain-details .details > div,
.strain-details .details ul {
  padding: 6px;
}

.strain-details .details .desc {
  padding: 14px 10px;
}

.strain-details .details .potency {
  text-align: center;
}

.strain-details .details .lineage {
  text-align: center;
}

.strain-details .details .lineage span {
  display: block;
  margin-bottom: 2px;
}

.strain-details .details .desc,
.strain-details .details .pheno,
.strain-details .details .lineage {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
}

.strain-details .details .dominance,
.strain-details .details .potency {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
}

.strain-details .details .desc {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / 2;
}

.strain-details .details .pheno {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
}

.strain-details .details .lineage {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3 / 4;
}

.strain-details .details .dominance {
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
  grid-row: 4 / 5;
}

.strain-details .details .potency {
  -ms-grid-row: 5;
  -ms-grid-row-span: 1;
  grid-row: 5 / 6;
}

.strain-details .details .terpenes {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 4;
  -ms-grid-row-span: 2;
  grid-row: 4 / 6;
  border-left: 1px solid #707070;
}

@media only screen and (min-width: 900px) {
  .strain-details .details .terpenes {
    border: none;
  }
}

.strain-details .details .desc,
.strain-details .details .pheno,
.strain-details .details .lineage,
.strain-details .details .dominance {
  border-bottom: 1px solid #707070;
}

@media only screen and (min-width: 900px) {
  .strain-details .details .desc,
  .strain-details .details .pheno,
  .strain-details .details .lineage,
  .strain-details .details .dominance {
    border: none;
  }
}

.strain-details .details .dominance span,
.strain-details .details .potency span,
.strain-details .details .terpenes span {
  display: block;
  margin-bottom: 3px;
}

@media only screen and (min-width: 900px) {
  .strain-details .details .dominance span,
  .strain-details .details .potency span,
  .strain-details .details .terpenes span {
    margin-bottom: 0;
  }
}

.nug-fig {
  padding-top: 40px;
}

.faq-page {
  max-width: 768px;
  margin: auto;
}

.img-details {
  padding-top: 50px;
}

.img-details .strain-art-wrapper {
  padding-bottom: 50px;
}

.img-details .strain-art-img {
  width: 100%;
  width: 90px;
  position: absolute;
  top: 163px;
  right: 66px;
}

.strain-index-page,
.heritage-page,
.strain-details-pages,
.img-content {
  background: #000000;
  color: #ffffff;
}

.black_back {
  background: #000000;
  color: #ffffff;
}

.white_back {
  background: #ffffff;
  color: #000000;
}

.slice-wrapper .slice-single.desk_half_w:nth-of-type(even) {
  border-top: 1px solid #3e3e3e;
}

.products-list {
  padding: 1rem 2rem;
}

.products-list .product {
  margin: 2rem auto;
}

.products-list .copy-wrapper {
  border-top: 1px solid #707070;
}

.products-list .copy-wrapper h2 {
  font-family: 'Gotham Book', sans-serif;
  text-transform: uppercase;
  font-size: 1.6rem;
}

.products-list .copy-wrapper .extra-wrapper {
  padding: 2rem;
}

.products-list .product-link {
  margin-top: 2rem;
  display: block;
}

.forms-list .form-wrapper .form {
  margin: 2rem auto;
  padding: 1rem 2rem;
}

.forms-list .form-wrapper .copy-wrapper h2 {
  font-family: 'Gotham Book', sans-serif;
  text-transform: uppercase;
  font-size: 1.6rem;
}

.forms-list .form-wrapper .copy-wrapper .extra-wrapper {
  padding: 1rem;
  margin-top: 1rem;
}

.forms-list .form-wrapper .copy-wrapper .extra-wrapper .description-name {
  margin-bottom: 0;
}

.forms-list .form-wrapper .copy-wrapper .extra-wrapper .description-copy p {
  margin: 5px auto 12px;
}

.forms-list .form-wrapper .shop-link {
  margin-top: 2rem;
  display: block;
}

.forms-list .form-wrapper:last-child .form {
  margin-bottom: 0;
  padding-bottom: 3rem;
}

@media only screen and (min-width: 768px) {
  .strains {
    padding-bottom: 65px;
  }
  .slice-wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 50% 50%;
        grid-template-columns: 50% 50%;
    grid-auto-flow: column;
  }
  .slice-wrapper .slice-single {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
  }
  .slice-wrapper .slice-single.desk_half_w:nth-of-type(even) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    border-top: none;
    border-right: 1px solid #3e3e3e;
  }
  .slice-wrapper .slice-single.desk_half_w:nth-of-type(odd) {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
  }
  .slice-wrapper .slice-single.desk_full_w .img-content .container {
    margin: auto;
    padding: 60px 40px;
  }
  .slice-wrapper .slice-single.desk_full_w .img-content .lazy-img-wrapper {
    max-width: 650px;
    margin: auto;
  }
  .slice-wrapper .slice-single.desk_full_w .img-content .sep-title h1,
  .slice-wrapper .slice-single.desk_full_w .img-content h2,
  .slice-wrapper .slice-single.desk_full_w .img-content h3 {
    max-width: 100%;
  }
  .slice-wrapper .slice-single.desk_full_w .img-content .copywrapper {
    max-width: 600px;
    margin: auto;
  }
  .cs-details {
    height: 320px;
  }
  .cs-details .cs-details-img {
    width: 130px;
  }
  .cs-info p {
    margin-top: 7px;
  }
  .cs-info .cs-info-img {
    width: 95px;
  }
  #jane-menu {
    margin-top: 70px;
  }
  .resp-grid-4col {
    -ms-grid-columns: (minmax(140px, 260px))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(140px, 260px));
  }
  .name h3 {
    margin-bottom: 5px;
  }
  .desk_half_w .container {
    max-width: 600px;
    margin: auto;
  }
  .desk_full_w .container {
    max-width: 800px;
  }
  .desk_full_w .full-background-hero .container {
    max-width: 1525px !important;
    width: 1525px;
    margin: auto;
  }
  .desk_full_w .standard-hero .container {
    max-width: 1100px;
    margin: auto;
  }
  .products-list {
    padding: 100px 2rem;
  }
  .products-list .product {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1450px;
    max-height: 615px;
    margin-bottom: 100px;
    overflow: hidden;
  }
  .products-list .product-img {
    max-width: 575px;
  }
  .products-list .product-img img {
    -o-object-position: center;
       object-position: center;
    width: inherit;
    height: 100%;
  }
  .products-list .copy-wrapper {
    border-top: none;
    border-left: 1px solid #707070;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .products-list .copy-wrapper h2 {
    font-size: 2rem;
    margin-bottom: 3rem;
  }
  .products-list .copy-wrapper .extra-wrapper {
    text-align: left;
    padding-left: 70px;
  }
  .forms-list .form-wrapper .form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1300px;
    margin: 3rem auto;
    padding: 2rem;
  }
  .forms-list .form-wrapper .form-img {
    height: 100%;
    width: 100%;
    max-width: 446px;
    max-height: 446px;
  }
  .forms-list .form-wrapper .form-img img {
    -o-object-fit: center;
       object-fit: center;
    -o-object-position: center;
       object-position: center;
  }
  .forms-list .form-wrapper .copy-wrapper {
    border-top: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .forms-list .form-wrapper .copy-wrapper h2 {
    font-size: 2rem;
    margin-bottom: 3rem;
  }
  .forms-list .form-wrapper .copy-wrapper .extra-wrapper {
    text-align: left;
    padding: 0 2rem 2rem 3rem;
    margin-top: 0;
  }
  .forms-list .form-wrapper:last-child .form {
    margin-bottom: 0;
    padding-bottom: 6rem;
  }
}

@media only screen and (min-width: 900px) {
  .opp-blocks {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: minmax(auto, 650px) 100px minmax(auto, 650px);
        grid-template-columns: minmax(auto, 650px) 100px minmax(auto, 650px);
    -ms-grid-rows: 540px 160px 540px 160px auto;
        grid-template-rows: 540px 160px 540px 160px auto;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 40px 0;
  }
  .opp-blocks .container {
    -ms-grid-column-align: center;
        justify-self: center;
  }
  .opp-blocks .d-right {
    margin-left: 30px;
  }
  .opp-blocks .d-left {
    margin-right: 30px;
  }
  .opp-blocks .block-1 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    grid-row: 1 / 4;
  }
  .opp-blocks .line-r-1 {
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-column: 2 / 4;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
  }
  .opp-blocks .block-2 {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    grid-column: 3 / 4;
    -ms-grid-row: 3;
    -ms-grid-row-span: 3;
    grid-row: 3 / 6;
  }
  .opp-blocks .line-l-1 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    grid-row: 4 / 5;
    -ms-grid-column-align: end;
        justify-self: end;
  }
  .opp-blocks .block-3 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    grid-row: 5 / 6;
  }
  .opp-blocks img {
    width: 450px;
    height: 450px;
  }
  .three-img-section,
  .img-grid-container {
    text-align: left;
  }
  .three-img-section .three-col-grid,
  .img-grid-container .three-col-grid {
    grid-gap: 3rem;
    padding-top: 30px;
  }
  .three-img-section .img-grid,
  .img-grid-container .img-grid {
    padding-top: 30px;
    grid-gap: 2rem;
  }
  .strain-desk-grid {
    display: -ms-inline-grid;
    display: inline-grid;
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    border: 1px solid #707070;
    margin: auto;
    width: 100%;
    max-width: 1350px;
    position: static;
  }
  .strain-desk-grid .img-details {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
    border-right: 1px solid #707070;
    border-top: none;
    border-left: none;
    position: relative;
    padding-top: 80px;
  }
  .strain-desk-grid .img-details .strain-art-img {
    left: 8px;
    top: 7px;
  }
  .strain-desk-grid .details {
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-column: 2 / 4;
    border: none;
    -ms-grid-columns: 50% 50%;
        grid-template-columns: 50% 50%;
    -ms-grid-rows: minmax(auto, 170px) minmax(50px, auto) minmax(55px, auto) minmax(120px, auto);
        grid-template-rows: minmax(auto, 170px) minmax(50px, auto) minmax(55px, auto) minmax(120px, auto);
  }
  .strain-desk-grid .details .detail-heading {
    margin-right: 5px;
  }
  .strain-desk-grid .details .terpenes .detail-heading {
    margin-bottom: 5px;
  }
  .strain-desk-grid .details .terpenes {
    text-align: left;
  }
  .strain-desk-grid .details .terpenes li {
    margin-bottom: 3px;
  }
  .strain-desk-grid .details .pheno,
  .strain-desk-grid .details .dominance,
  .strain-desk-grid .details .terpenes {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / 2;
  }
  .strain-desk-grid .details .lineage,
  .strain-desk-grid .details .potency,
  .strain-desk-grid .details button {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / 3;
  }
  .strain-desk-grid .details .pheno,
  .strain-desk-grid .details .lineage {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
  }
  .strain-desk-grid .details .dominance,
  .strain-desk-grid .details .potency {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3 / 4;
  }
  .strain-desk-grid .details .terpenes,
  .strain-desk-grid .details button {
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    grid-row: 4 / 5;
  }
  .strain-desk-grid .details .pheno,
  .strain-desk-grid .details .dominance,
  .strain-desk-grid .details .terpenes {
    border-right: 1px solid #707070;
  }
  .strain-desk-grid .details .pheno,
  .strain-desk-grid .details .lineage,
  .strain-desk-grid .details .dominance,
  .strain-desk-grid .details .potency,
  .strain-desk-grid .details .desc {
    border-bottom: 1px solid #707070;
  }
  .strain-info-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .strain-info-item.terpenes {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .strain-info-item.shop-now-button {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .strain-details {
    text-align: center;
    padding-top: 120px;
  }
  .strain-details .strain-wrapper {
    padding: 50px 20px 25px;
    position: relative;
  }
  .strain-details .nug-img {
    width: auto;
    margin: auto;
    padding-top: 50px;
    top: 7px;
  }
  .strain-details .details .desc {
    text-align: left;
    padding: 30px;
  }
  .strain-details .details > div:not(.shop-now-button),
  .strain-details .details ul {
    padding-left: 30px;
  }
}

.espanol-page {
  height: 100vh;
  background: #000000;
}

.espanol-page .img-content {
  padding-top: 150px;
}

.menu-icon.animate-burger {
  -webkit-animation: fadeIn 200ms;
          animation: fadeIn 200ms;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 10%;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 10%;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  font-family: 'Gotham Medium', sans-serif;
}

button:disabled {
  cursor: default;
}

.shop-buttons {
  font-size: 0.9rem !important;
}

.black-plus {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 25px;
  width: 25px;
  background: #707070;
  color: #000000;
}

.button-container {
  margin-top: 30px;
}

.button-container a {
  display: inline-block;
  margin-bottom: 1.5rem;
}

.back-button {
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 1px;
  color: #ffffff;
}

.back-button .arrow {
  width: 15px;
  position: relative;
  bottom: 15px;
}

.floating-faq {
  bottom: 20px;
  position: fixed;
  right: 20px;
  z-index: 2;
}

.paginate-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  max-width: 300px;
  margin: auto;
}

.paginate-btn {
  color: #ffffff;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.75rem;
  cursor: pointer;
}

.paginate-btn span {
  margin-top: 3px;
}

.paginate-btn .arrow-l {
  width: 10px;
  margin-right: 4px;
}

.paginate-btn .arrow-r {
  width: 19px;
  margin-left: 4px;
}

.paginate-btn.largeDesktop {
  position: absolute;
  z-index: 2;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.paginate-btn.largeDesktop.prev-strain {
  left: -90px;
}

.paginate-btn.largeDesktop.next-strain {
  right: -90px;
}

.paginate-btn.largeDesktop span {
  margin-top: 0px;
}

.paginate-btn.largeDesktop .arrow-l {
  width: 7px;
  margin-right: 6px;
}

.paginate-btn.largeDesktop .arrow-r {
  width: 12px;
  margin-left: 4px;
}

@media only screen and (min-width: 900px) {
  .back-button {
    position: absolute;
    top: 15px;
    left: 10px;
    margin-top: 7px;
  }
  .black-plus {
    height: 40px;
    width: 40px;
    font-size: 1.5rem;
  }
}

.add-scribble,
.sep-title h1,
.sep-title h2,
.sep-title h3,
.standard-hero h1,
#contact-page h1,
.three-img-section h2,
#modal-title,
.content-slice h1 {
  position: relative;
  display: inline-block;
  max-width: 450px;
  margin: 0 auto 1.5rem;
}

.add-scribble:after,
.sep-title h1:after,
.sep-title h2:after,
.sep-title h3:after,
.standard-hero h1:after,
#contact-page h1:after,
.three-img-section h2:after,
#modal-title:after,
.content-slice h1:after {
  position: absolute;
  top: 76%;
  left: 0%;
  height: 163%;
  content: '';
  width: 100%;
  background: url(../images/Scribbles/line.svg) no-repeat;
  display: block;
}

.product-underline a {
  position: relative;
}

.product-underline a:after {
  position: absolute;
  content: '';
  display: block;
  width: 105%;
}

.product-underline.flower-link a:after {
  left: 50%;
  top: 76%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: url(../images/ProductScribbles/flower.svg) no-repeat;
  height: 14px;
}

.product-underline.vapes-link a:after {
  left: 50%;
  top: 76%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: url(../images/ProductScribbles/vape.svg) no-repeat;
  height: 20px;
  width: 130%;
  height: 14px;
}

.product-underline.concentrate-link a:after {
  left: 50%;
  top: 76%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: url(../images/ProductScribbles/concentrates.svg) no-repeat;
  height: 19px;
}

.product-underline.prerolls-link a:after {
  left: 59%;
  top: 76%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: url(../images/ProductScribbles/prerolls.svg) no-repeat;
  height: 14px;
}

.product-underline.edibles-link a:after {
  left: 50%;
  top: 76%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: url(../images/ProductScribbles/edibles.svg) no-repeat;
  height: 14px;
}

.full-background-hero .add-scribble {
  margin-bottom: 0;
}

.full-background-hero .add-scribble:after {
  top: 68%;
  left: -6%;
}

.sep-title {
  margin-bottom: 2.8rem;
}

.circ-scrib-cont {
  margin-top: 5px;
}

.circ-scrib-cont .the-circ-scrib {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 88px;
}

@media only screen and (min-width: 800px) {
  .desk_full_w .img-content .sep-title h1,
  .desk_full_w .img-content h2,
  .desk_full_w .img-content h3 {
    margin-bottom: 40px;
  }
  .desk_full_w .img-content .sep-title h1:after,
  .desk_full_w .img-content h2:after,
  .desk_full_w .img-content h3:after {
    left: 60%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

@media only screen and (min-width: 900px) {
  .circ-scrib-cont {
    margin-top: 0px;
    margin-left: 16px;
  }
  .product-underline a:after {
    width: 105%;
  }
  .product-underline.flower-link a:after {
    left: 50%;
    top: 76%;
    height: 22px;
  }
  .product-underline.vapes-link a:after {
    left: 50%;
    top: 76%;
    width: 131%;
    height: 35px;
  }
  .product-underline.concentrate-link a:after {
    left: 50%;
    top: 76%;
    height: 30px;
  }
  .product-underline.prerolls-link a:after {
    left: 50%;
    top: 76%;
    height: 29px;
  }
  .product-underline.edibles-link a:after {
    left: 50%;
    top: 76%;
    height: 35px;
  }
}

@media only screen and (min-width: 1200px) {
  .circ-scrib-cont .the-circ-scrib {
    width: 110px;
  }
}

.mobile-line {
  height: 95px;
  display: block;
  margin: auto;
}

.desktop-line {
  width: 400px;
}

.hi .svg-line {
  stroke-dasharray: 90;
  stroke-dashoffset: 90;
  -webkit-animation: dash 2s linear forwards;
          animation: dash 2s linear forwards;
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.page-wrapper {
  margin-top: 120px;
}

.page-title {
  margin-bottom: 40px;
}

.map-wrapper {
  overflow: auto;
  background-size: cover;
}

.map-wrapper .interactive-map {
  width: 100%;
  position: relative !important;
  display: block !important;
  height: 30vh !important;
}

.map-wrapper .locations-list-wrapper {
  display: block;
  height: calc(80vh - 100px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.map-wrapper .locations-list-wrapper.store-wrapper {
  padding: 2rem;
}

.map-wrapper .locations-list-wrapper .locations-list-title {
  margin: 0;
  padding: 0px 20px 0 0;
  color: #000000;
}

.map-wrapper .locations-list-wrapper .pagination-nav {
  padding: 8px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #f0f0f0;
  color: #000000;
}

.map-wrapper .locations-list-wrapper .pagination-nav .page-controls span {
  cursor: pointer;
}

.map-wrapper .locations-list-wrapper .pagination-nav .page-controls span:nth-of-type(1) {
  margin-right: 10px;
}

.map-wrapper .info-message {
  background: #000000;
  color: white;
  padding: 8px 20px;
}

.map-wrapper .locations-search-form {
  padding: 10px 20px 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.map-wrapper .locations-search-form-wrapper {
  padding: 20px 20px 0 20px;
}

.map-wrapper .locations-list {
  background: white;
  overflow: scroll;
  height: 100%;
  position: relative;
  border-right: 1px solid #f5f5f5;
  border-left: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}

.map-wrapper .locations-list .single-location {
  padding: 20px;
  overflow: auto;
  border-bottom: 1px solid #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.map-wrapper .locations-list .single-location:last-of-type {
  border-bottom: 0;
}

.map-wrapper .locations-list .single-location.active {
  background: #f0f0f0;
}

.map-wrapper .locations-list .single-location .store-information {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #000000;
  width: 100%;
  color: #000000;
}

.map-wrapper .locations-list .single-location .store-information .store-name {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 5px;
  color: #000000;
}

.map-wrapper .locations-list .single-location .store-information .store-address {
  font-size: 14px;
  line-height: 1.2;
  margin: 0;
}

.map-wrapper .locations-list .single-location .store-information .distance {
  font-size: 14px;
  line-height: 1.2;
  margin: 8px 0 0 0;
  font-style: italic;
  opacity: 0.7;
}

.map-wrapper .locations-list .single-location .store-information .usage-type {
  font-size: 12px;
  font-weight: 900;
  margin: 5px 0 10px;
}

.map-wrapper .locations-list .single-location .store-actions {
  min-width: 110px;
}

.map-wrapper .locations-list .single-location .store-actions .icon-button {
  color: #000000;
  font-size: 10px;
  display: block;
  text-decoration: none;
  font-weight: 900;
  margin-bottom: 10px;
  white-space: nowrap;
}

.map-wrapper .locations-list .single-location .store-actions .icon-button svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  fill: #000000;
}

.info-window-content .store-name {
  font-size: 1.1rem;
}

.info-window-content .usage-type {
  margin: 5px 0 0 0;
  font-size: 0.9rem;
}

.info-window-content .store-address {
  margin: 5px 0 10px;
}

.title-mobile {
  display: block;
  text-align: center;
  padding: 4rem 2rem 1rem 2rem;
}

.title-desktop {
  display: none;
}

@media only screen and (min-width: 600px) {
  .map-wrapper .interactive-map {
    height: 45vh !important;
  }
}

@media only screen and (min-width: 768px) {
  .page-wrapper {
    margin-top: 60px;
  }
  .page-title {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 1040px) {
  .map-wrapper {
    overflow: auto;
    padding: 50px 80px 50px 80px;
  }
  .map-wrapper .interactive-map {
    height: calc(80vh - 100px) !important;
    width: 60% !important;
    float: right;
    display: block;
  }
  .map-wrapper .stores-wrapper {
    height: calc(800vh - 100px) !important;
    width: 40%;
    float: left;
  }
  .map-wrapper .locations-list-wrapper.store-wrapper {
    padding: 0;
  }
  .map-wrapper .locations-search-form-wrapper {
    padding: 0 20px 0 0;
  }
  .info-message,
  .pagination-nav,
  .locations-list {
    width: 90%;
  }
  .title-mobile {
    display: none;
  }
  .title-desktop {
    display: block;
  }
}

.carousel .slide {
  margin-bottom: 35px;
}

.carousel .slide .squareContainer {
  display: block;
  margin-right: 2%;
}

.carousel .control-dots {
  background: #f5f5f5;
  display: block;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
}

.carousel .control-dots .dot {
  border-radius: 0;
  width: 100%;
  margin: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.carousel .control-dots .dot.selected {
  background: #c47200;
}

.carousel.carousel-slider .control-arrow {
  display: none !important;
}

.sfc-wrapper {
  position: relative;
  margin-top: 40px;
}

h3.add-scribble {
  margin: 20px auto;
}

.custom-carousel-btn {
  position: absolute;
  z-index: 3;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 40%;
}

.custom-carousel-btn svg {
  width: 25px;
  height: 25px;
}

.custom-carousel-btn.btn-l {
  left: -42px;
}

.custom-carousel-btn.btn-r {
  right: -41px;
}

footer {
  background: #000000;
  color: white;
  border-top: 1px solid #3e3e3e;
}

footer .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 1100px;
  margin: auto;
}

footer .wrapper .footer-section {
  margin-bottom: 25px;
  width: 50%;
}

footer .wrapper .footer-section p {
  margin-bottom: 5px;
}

footer .wrapper .footer-section p a {
  color: #ffffff;
}

footer .wrapper .footer-section p a:hover, footer .wrapper .footer-section p a:focus {
  text-decoration: underline;
}

footer .wrapper .footer-section p:last-of-type {
  margin-bottom: 0;
}

footer .wrapper .footer-section .copyright {
  margin-bottom: 15px;
  display: block;
}

footer .wrapper .footer-section .social {
  margin-top: 20px;
}

footer .wrapper .footer-section .social .social-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

footer .wrapper .footer-section .social .social-icons .social-link {
  margin-right: 10px;
  width: 20px;
  height: auto;
  margin-bottom: 0;
}

footer .wrapper .footer-section .social .social-icons .social-link .social-icon {
  width: 100%;
  height: auto;
}

footer .wrapper .footer-section a {
  display: block;
  margin-bottom: 10px;
  text-decoration: none;
  font-weight: normal;
}

footer .wrapper .footer-section a:last-of-type {
  margin-bottom: 0;
}

footer .wrapper .footer-section:first-of-type, footer .wrapper .footer-section:last-of-type {
  width: 100%;
}

footer .wrapper .footer-section:last-of-type {
  margin-bottom: 0;
}

@media only screen and (min-width: 900px) {
  footer .wrapper {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  footer .wrapper .footer-section {
    margin-right: 20px;
    width: auto;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  footer .wrapper .footer-section p {
    margin-top: 5px;
  }
  footer .wrapper .footer-section .social {
    margin: 0;
  }
  footer .wrapper .footer-section .copyright {
    margin-top: 0;
  }
  footer .wrapper .footer-section:last-of-type {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}

@media only screen and (min-width: 1280px) {
  footer .wrapper .footer-section p {
    margin: 0;
  }
}
