.carousel {
  .slide {
    margin-bottom: 35px;
    .squareContainer {
      display: block;
      margin-right: 2%;
    }
  }
  .control-dots {
    background: $lite-gray;
    display: block;
    margin: 0;
    display: flex;
    justify-content: stretch;
    .dot {
      border-radius: 0;
      width: 100%;
      margin: 0;
      box-shadow: none;

      &.selected {
        background: $brown;
      }
    }
  }
  &.carousel-slider .control-arrow {
    display: none !important;
  }
}

.sfc-wrapper {
  position: relative;
  margin-top: 40px;
}
h3.add-scribble {
  margin: 20px auto;
}

.custom-carousel-btn {
  position: absolute;
  z-index: 3;
  position: absolute;
  transform: translateY(-50%);
  top: 40%;
  svg {
    width: 25px;
    height: 25px;
  }
  &.btn-l {
    left: -42px;
  }
  &.btn-r {
    right: -41px;
  }
}

@media only screen and (min-width: 768px) {
  // .carousel {
  //   .slide {
  //     margin-right: 2%;
  //   }
  // }
}
