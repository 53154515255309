.padding-lr {
  padding: 0 40px;
}

.padding-all {
  padding: 40px;
}

.padding-noTop {
  padding: 0px 40px 40px 40px;
}
.padding-noBot {
  padding: 40px 40px 0 40px;
}

.padding-10 {
  padding: 10px;
}

.padding-all-30 {
  padding: 30px;
}

.margin-all-15 {
  margin: 15px;
}

.margin-all-30 {
  margin: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.m-30-auto {
  margin: 30px auto 20px;
}

figure {
  margin: 0;
  padding: 0;
}

.center,
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-row-spaced {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-row-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.shrink-0 {
  flex-shrink: 0;
}

.justify-start {
  justify-content: flex-start;
}

.justify-spaced {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-around {
  justify-content: space-around;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-self-top {
  align-self: flex-start;
}
.order-1 {
  order: 1;
}

ul {
  margin: 0;
  padding-left: 0;
  li {
    text-decoration: none;
    list-style: none;
  }
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.upper {
  text-transform: uppercase;
}

.lower {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.center-xy {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.invert {
  filter: brightness(0) invert(1);
}

.border-radius-small {
  border-radius: 4px;
}

.border-radius-medium {
  border-radius: 8px;
}

.border-radius-large {
  border-radius: 16px;
}

.nowrap {
  white-space: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inline-flex {
  display: inline-flex;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.width-700 {
  max-width: 700px;
}

.width-480 {
  max-width: 480px;
  margin: auto;
}

.width-1100 {
  max-width: 1100px;
  margin: auto;
}

.width-50 {
  width: 50%;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.line-through {
  text-decoration: line-through;
}

.grow-1 {
  flex-grow: 1;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.inline {
  display: inline;
}

strong,
b {
  font-weight: bold;
}

.font-medium.bold,
.font-medium > strong {
  font-family: 'Gotham Bold', sans-serif;
  letter-spacing: 0;
}

.font-book {
  font-family: 'Gotham Book', sans-serif;
  letter-spacing: -0.04rem;
}

.font-book.bold,
.font-book > strong {
  font-family: 'Gotham Medium', sans-serif;
  letter-spacing: 0;
}

.opacity-0 {
  opacity: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.preline {
  white-space: pre-line;
}

.first-child-m0:first-child {
  margin: 0;
}

.fill-inherit {
  fill: inherit;
}

.fill-inherit path {
  fill: inherit;
}

.disabled-opacity {
  opacity: 0.4;
}

.m-0-auto {
  margin: 0 auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-30 {
  margin-top: 30px;
}

div.bg-white-hover:hover,
button.bg-white-hover:hover {
  background-color: #efefef;
}

.no-text-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
}

.hide-scrollbars {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbars::-webkit-scrollbar {
  display: none;
}

.opacity-hover:hover {
  opacity: 0.75;
}

.dot-icon {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.overflow-visible {
  overflow: visible;
}

/* Remove the clear icon on seach inputs */
input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='text']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='text']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.screen-reader-only {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  overflow: hidden !important;
}

.contain-img {
  display: block;
  max-width: 100%;
  width: 100%;
  object-fit: contain;
}

.cover-img {
  display: block;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}

.no-padding-left {
  padding-left: 0;
}
.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin: auto;
}

.inherit-height {
  height: inherit;
}

.no-line-break {
  white-space: nowrap;
}

.mr-10 {
  margin-right: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-25 {
  margin-left: 25px;
}

.mr-20 {
  margin-right: 20px;
}

.bord-bott {
  border-bottom: 1px solid $black;
}
.bord-bott-white {
  border-bottom: 1px solid $white;
}

.bord-bott-gray {
  border-bottom: 1px solid $med-gray;
}

.bord-top-bottom {
  border-bottom: 1px solid $dark-gray;
  border-top: 1px solid $dark-gray;
}

.bord-bott-all {
  border: 1px solid $med-gray;
}
.black-background {
  background: $black;
  color: $white;
}
.copy-wrapper {
  max-width: 800px;
}

.ada-switch {
  .MuiSwitch-track {
    background-color: $lite-gray !important;
  }
  .Mui-checked .MuiIconButton-label {
    color: $brown;
  }
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.width-100 {
  width: 100%;
}

.button-width-style {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.nowrap {
  white-space: nowrap;
}
