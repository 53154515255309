nav {
  background: $black;
  min-height: 60px;
  position: fixed;
  width: 90%;
  margin: auto;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50px;
  border: 1px solid $dark-gray;
  z-index: 4;

  .mobile-bar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }
  ul {
    display: none;
    // visibility: hidden;
    text-align: center;
    // transition: opacity 1s ease;
    // opacity: 0;
    a {
      text-transform: uppercase;
      color: $white;
      display: block;
      padding: 7px;
    }
    li:last-of-type {
      padding-bottom: 18px;
    }
    &.openNav {
      display: block;
      // visibility: visible;
      // opacity: 1;
    }
  }

  #nav-logo img {
    width: 140px;
  }
}

#simple-menu a {
  color: $white;
  font-family: 'Gotham Book', sans-serif;
}

@media only screen and (min-width: 768px) {
  nav {
    top: 0;
    width: 100%;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $dark-gray;

    .nav-wrapper {
      display: flex;
      justify-content: space-between;
      max-width: 1550px;
      margin: auto;
    }

    ul {
      display: flex;
      justify-content: center;
      align-items: center;

      li {
        margin-right: 25px;
      }

      li:last-of-type {
        padding-bottom: 0;
      }
    }
  }
}
