.add-scribble,
.sep-title h1,
.sep-title h2,
.sep-title h3,
.standard-hero h1,
#contact-page h1,
.three-img-section h2,
#modal-title,
.content-slice h1 {
  position: relative;
  display: inline-block;
  max-width: 450px;
  margin: 0 auto 1.5rem;

  &:after {
    position: absolute;
    top: 76%;
    left: 0%;
    height: 163%;
    content: '';
    width: 100%;
    background: url(../images/Scribbles/line.svg) no-repeat;
    display: block;
  }
}

// PRODUCTS SCRIBBES
.product-underline {
  a {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 105%;
    }
  }
}

.product-underline.flower-link {
  a:after {
    left: 50%;
    top: 76%;
    transform: translateX(-50%);
    background: url(../images/ProductScribbles/flower.svg) no-repeat;
    height: 14px;
  }
}

.product-underline.vapes-link {
  a:after {
    left: 50%;
    top: 76%;
    transform: translateX(-50%);
    background: url(../images/ProductScribbles/vape.svg) no-repeat;
    height: 20px;
    width: 130%;
    height: 14px;
  }
}

.product-underline.concentrate-link {
  a:after {
    left: 50%;
    top: 76%;
    transform: translateX(-50%);
    background: url(../images/ProductScribbles/concentrates.svg) no-repeat;
    height: 19px;
  }
}

.product-underline.prerolls-link {
  a:after {
    left: 59%;
    top: 76%;
    transform: translateX(-50%);
    background: url(../images/ProductScribbles/prerolls.svg) no-repeat;
    height: 14px;
  }
}

.product-underline.edibles-link {
  a:after {
    left: 50%;
    top: 76%;
    transform: translateX(-50%);
    background: url(../images/ProductScribbles/edibles.svg) no-repeat;
    height: 14px;
  }
}

// PRODUCTS SCRIBBES

.full-background-hero .add-scribble {
  margin-bottom: 0;
  &:after {
    top: 68%;
    left: -6%;
  }
}

.sep-title {
  margin-bottom: 2.8rem;
}

.circ-scrib-cont {
  margin-top: 5px;
  .the-circ-scrib {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 88px;
  }
}

@media only screen and (min-width: 800px) {
  .desk_full_w .img-content {
    .sep-title h1,
    h2,
    h3 {
      margin-bottom: 40px;
      &:after {
        left: 60%;
        transform: translateX(-50%);
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .circ-scrib-cont {
    margin-top: 0px;
    margin-left: 16px;
  }
  // PRODUCT SCRIBLLES
  .product-underline {
    a {
      &:after {
        width: 105%;
      }
    }
  }

  .product-underline.flower-link {
    a:after {
      left: 50%;
      top: 76%;
      height: 22px;
    }
  }

  .product-underline.vapes-link {
    a:after {
      left: 50%;
      top: 76%;
      width: 131%;
      height: 35px;
    }
  }

  .product-underline.concentrate-link {
    a:after {
      left: 50%;
      top: 76%;
      height: 30px;
    }
  }

  .product-underline.prerolls-link {
    a:after {
      left: 50%;
      top: 76%;
      height: 29px;
    }
  }

  .product-underline.edibles-link {
    a:after {
      left: 50%;
      top: 76%;
      height: 35px;
    }
  }
  //PRODUCT SCRIBBLES
}

@media only screen and (min-width: 1200px) {
  .circ-scrib-cont {
    .the-circ-scrib {
      width: 110px;
    }
  }
}
