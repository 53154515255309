#contact-page {
  margin-top: 100px;
  .wrapper {
    max-width: 700px;
    margin: auto;
    margin-bottom: 40px;
  }
}

#contact-header {
  text-align: center;
  h1 {
    text-align: center;
    padding: 40px 0px;
    margin: 0;
  }
}

.form-message {
  display: block;
  background: $black;
  color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  a {
    color: white;
    text-decoration: underline;
  }
  &.error {
    color: rgb(173, 0, 0);
    background: none;
    border: 2px solid rgb(173, 0, 0);
  }

  &.success .brandColor {
    font-weight: bold;
  }
}

.tooltipButton {
  margin: 0;
  padding: 0;
  height: 18px;
  width: 18px;
  background: none;
  border: none;
  cursor: pointer;
}

//FAQ
.faq-wrapper {
  margin: 15px 0 0 15px;
}
.answer p {
  margin: 0;
}

.faq-error {
  h3 {
    margin-bottom: 0;
  }
  a {
    color: $black;
    text-decoration: underline;
    font-weight: bold;
  }
}
#menu-select-strain {
  .MuiMenuItem-root,
  .MuiMenu-list {
    background-color: $white !important;
    color: $black !important;
  }
  .MuiListItem-root {
    background-color: $white !important;
    color: $black !important;
    &:hover {
      background-color: $lite-gray !important;
    }
  }
}
.white-select {
  .MuiOutlinedInput-notchedOutline {
    border: 2px solid $white !important;
    &:hover {
      border: 2px solid $white !important;
    }
  }
  .MuiSelect-nativeInput,
  .MuiSelect-root,
  .MuiFormLabel-root {
    color: $white !important;
  }
  .MuiSelect-icon {
    fill: $white !important;
  }
}
