.page-wrapper {
  margin-top: 120px;
}
.page-title {
  margin-bottom: 40px;
}
.map-wrapper {
  overflow: auto;
  background-size: cover;

  .interactive-map {
    width: 100%;
    position: relative !important;
    display: block !important;
    height: 30vh !important;
  }
  .locations-list-wrapper {
    display: block;
    height: calc(80vh - 100px);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    &.store-wrapper {
      padding: 2rem;
    }
    .locations-list-title {
      margin: 0;

      padding: 0px 20px 0 0;
      color: $black;
    }
    .pagination-nav {
      padding: 8px 20px;
      display: flex;
      justify-content: space-between;

      background: #f0f0f0;
      color: $black;
      .page-controls span {
        cursor: pointer;
        &:nth-of-type(1) {
          margin-right: 10px;
        }
      }
    }
  }
  .info-message {
    background: $black;
    color: white;
    padding: 8px 20px;
  }
  .locations-search-form {
    padding: 10px 20px 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .locations-search-form-wrapper {
    padding: 20px 20px 0 20px;
  }
  .locations-list {
    background: white;
    overflow: scroll;
    height: 100%;
    position: relative;
    border-right: 1px solid $lite-gray;
    border-left: 1px solid $lite-gray;
    border-bottom: 1px solid $lite-gray;
    .single-location {
      padding: 20px;
      overflow: auto;
      border-bottom: 1px solid $black;
      display: flex;
      flex-direction: row;
      &:last-of-type {
        border-bottom: 0;
      }
      &.active {
        background: #f0f0f0;
      }

      .store-information {
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid $black;
        width: 100%;
        color: $black;
        .store-name {
          font-size: 16px;
          line-height: 1.2;
          margin-bottom: 5px;
          color: $black;
        }
        .store-address {
          font-size: 14px;
          line-height: 1.2;
          margin: 0;
        }
        .distance {
          font-size: 14px;
          line-height: 1.2;
          margin: 8px 0 0 0;
          font-style: italic;
          opacity: 0.7;
        }
        .usage-type {
          font-size: 12px;
          font-weight: 900;
          margin: 5px 0 10px;
        }
      }
      .store-actions {
        min-width: 110px;
        .icon-button {
          color: $black;
          font-size: 10px;
          display: block;
          text-decoration: none;
          font-weight: 900;
          margin-bottom: 10px;
          white-space: nowrap;
          svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
            fill: $black;
          }
        }
      }
    }
  }
}

.info-window-content {
  .store-name {
    font-size: 1.1rem;
  }
  .usage-type {
    margin: 5px 0 0 0;
    font-size: 0.9rem;
  }
  .store-address {
    margin: 5px 0 10px;
  }
}

.title-mobile {
  display: block;
  text-align: center;

  padding: 4rem 2rem 1rem 2rem;
}
.title-desktop {
  display: none;
}

@media only screen and (min-width: 600px) {
  .map-wrapper {
    .interactive-map {
      height: 45vh !important;
    }
  }
}

@media only screen and (min-width: 768px) {
  .page-wrapper {
    margin-top: 60px;
  }
  .page-title {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 1040px) {
  .map-wrapper {
    overflow: auto;
    padding: 50px 80px 50px 80px;
    .interactive-map {
      height: calc(80vh - 100px) !important;
      width: 60% !important;
      float: right;
      display: block;
    }
    .stores-wrapper {
      height: calc(800vh - 100px) !important;
      width: 40%;
      float: left;
    }
    .locations-list-wrapper {
      &.store-wrapper {
        padding: 0;
      }
    }
    .locations-search-form-wrapper {
      padding: 0 20px 0 0;
    }
  }

  .info-message,
  .pagination-nav,
  .locations-list {
    width: 90%;
  }
  .title-mobile {
    display: none;
  }
  .title-desktop {
    display: block;
  }
}
