.menu-icon {
  &.animate-burger {
    animation: fadeIn 200ms;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 10%;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

// .nug-fig {
//   transform: translatey(0px);
//   animation: float 7s ease-in-out infinite;
// }

// @keyframes float {
//   0% {
//     transform: translatey(0px);
//   }

//   50% {
//     transform: translatey(18px);
//   }
//   100% {
//     transform: translatey(0px);
//   }
// }
