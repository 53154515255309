.mobile-line {
  height: 95px;
  display: block;
  margin: auto;
}
.desktop-line {
  width: 400px;
}
// .opp-blocks .animate-line:last-child {
//   display: none;
// }

.hi .svg-line {
  stroke-dasharray: 90;
  stroke-dashoffset: 90;
  animation: dash 2s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
