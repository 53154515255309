html,
body {
  height: 100%;
  &.whiteBackground {
    background: white !important;
    color: black !important;
  }
  &.blackBackground {
    background: black;
    color: white;
  }
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Gotham Book', sans-serif;
}

#app-body {
  min-height: 100vh;
}

.loading-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: $black;
  top: 0;
  left: 0;
  .circ-progress {
    color: $brown;
  }
}
