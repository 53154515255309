@font-face {
  font-family: 'Gotham Book Regular';
  src: url('../fonts/Gotham Book Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham Bold';
  src: url('../fonts/Gotham-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham Book';
  src: url('../fonts/Gotham-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham Light';
  src: url('../fonts/Gotham-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham Medium';
  src: url('../fonts/Gotham-Medium.otf') format('opentype');
}

.fine-print {
  font-size: 12px !important;
  font-family: Gotham-Book, sans-serif;
  color: $med-gray;
  line-height: 25px;
  a {
    color: $med-gray;
    text-decoration: underline;
  }
}

.font-bold {
  font-family: 'Gotham Bold', sans-serif;
}

.font-thin {
  font-family: Gotham-Light, sans-serif;
  font-weight: 100;
}

.font-normal {
  font-family: 'Gotham Book', sans-serif;
}

.line-space {
  letter-spacing: 1.1px;
}

.letter-space-2 {
  letter-spacing: 2.5px;
}

.caslon {
  font-family: adobe-caslon-pro, serif;
  font-weight: 600;
  font-style: normal;
}

.caslon-it {
  font-family: adobe-caslon-pro, serif;
  font-weight: 600;
  font-style: italic;
}

.cs-info {
  font-size: 0.7rem;
}

h1:not(.font-normal),
h2:not(.font-normal) {
  font-size: 2.1rem;
  font-family: adobe-caslon-pro, serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
}

#modal-title {
  font-family: 'Gotham Bold', sans-serif;
}

p {
  line-height: 23px;
}
.name {
  h3 {
    font-size: 0.625rem;
  }
  h4 {
    font-size: 0.75rem;
  }
}

.detail-heading {
  font-family: Gotham-Light, sans-serif;
  font-weight: 100;
  letter-spacing: 2.65px;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.img-details {
  span {
    font-size: 0.9rem;
  }
  h1 {
    font-size: 1.1rem;
  }
}

.store-information,
.info-window-content {
  .store-name {
    font-family: 'Gotham Bold', sans-serif;
  }
}

.footer-section {
  h2 {
    font-family: 'Gotham Bold', serif;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 10px;
    letter-spacing: 1.1px;
  }
  .cresco {
    letter-spacing: 3.5px;
  }
  p {
    letter-spacing: 1.1px;
    font-size: 0.8rem;
    margin-top: 10px;
  }
}

.faq-module-wrapper {
  h2 {
    font-size: 1.2rem;
  }
}
.faq-page {
  padding-bottom: 90px;
  h2 {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 768px) {
  .name {
    h3 {
      font-size: 0.85rem;
    }
    h4 {
      font-size: 1rem;
    }
  }
  .cs-info {
    font-size: 1rem;
  }

  .copywrapper.altHeadingCopy {
    h1 {
      font-size: 50px;
    }
  }
}

@media only screen and (min-width: 900px) {
  h1:not(.font-normal) {
    font-size: 3.4rem;
  }
  h2:not(.font-normal) {
    font-size: 2.8rem;
  }
  p {
    font-size: 1.1rem;
    line-height: 26px;
  }
  .footer-section {
    h2 {
      font-size: 1rem;
    }
  }
  .faq-module-wrapper {
    h2 {
      font-size: 1.3rem;
    }
  }
  .faq-page {
    h2 {
      font-size: 1.6rem;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .detail-heading {
    font-size: 1.1rem;
  }
  .strain-info-item {
    font-size: 1.1rem;
  }
  .img-details {
    span {
      font-size: 1.25rem;
      display: block;
      margin-bottom: 8px;
    }
    h1 {
      font-size: 1.5rem;
    }
  }
}
