#jane-menu {
  margin-top: 130px;
}

.content-slice {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
  a {
    color: $black;
    text-decoration: underline;
  }
}

.opp-blocks {
  max-width: 1400px;
  margin: auto;

  img {
    width: 300px;
    height: 300px;
  }
  .container {
    max-width: 650px;
  }
  .copy-wrapper {
    h2,
    h3 {
      margin: 30px auto 20px;
    }
  }
}

.three-img-section,
.img-grid-container {
  padding: 20px 0 5px 0;
  border-bottom: 1px solid $dark-gray;
  text-align: center;
  .container {
    padding-top: 2rem;
  }
  .three-col-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1rem;
    max-width: 1250px;
    margin: auto;
    padding-top: 20px;
  }
  .img-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1rem;
    max-width: 1800px;
    margin: auto;
    padding-top: 20px;
    .link-hover {
      transition: 500ms ease-out;
      opacity: 1;
      &:hover {
        opacity: 0.8;
      }
    }

    h3 {
      color: white;
    }
  }
}

.resp-grid-4col {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-gap: 0.5rem;
  justify-content: center;
  max-width: 1400px;
  margin: auto;
}

.strains {
  padding-bottom: 55px;
}

.cs-copy {
  text-transform: capitalize;
}

.cs-details {
  width: 100%;
  height: 180px;
  p {
    margin: 10px 0 0 0;
  }
  .cs-details-img {
    width: 85px;
  }
}

.cs-info {
  p {
    margin-top: 5px;
    font-size: 0.8rem;
  }
  .cs-info-img {
    width: 60px;
  }
}

.img-content {
  height: 100%;
}

.strain-info {
  color: $white;
  border: 1px solid $med-gray;
  margin: 0;
  width: 100%;
  h3,
  h4 {
    margin: 0;
  }
}

.nam.lineagee {
  h3 {
    margin-bottom: 3px;
  }
}

//STRAIN DETAILS PAGE START

.strain-details {
  position: relative;
  padding-top: 120px;
  padding-bottom: 70px;
  background: $black;
  color: $white;

  h1 {
    margin: 0;
  }
  .strain-wrapper {
    padding: 20px;
    max-width: 1350px;
    margin: auto;
  }
  .details {
    border: 1px solid $med-gray;
  }
  .img-details {
    border-top: 1px solid $med-gray;
    border-right: 1px solid $med-gray;
    border-left: 1px solid $med-gray;
  }

  .strain-cta {
    border-bottom: 1px solid $med-gray;
    border-right: 1px solid $med-gray;
    border-left: 1px solid $med-gray;
  }

  .details {
    display: grid;
    grid-template-columns: 45% 55%;
    grid-template-rows: repeat(5, auto);
    font-size: 0.8rem;
    text-align: center;

    > div,
    ul {
      padding: 6px;
    }
    .desc {
      padding: 14px 10px;
    }

    .potency {
      text-align: center;
    }
    .lineage {
      text-align: center;
      span {
        display: block;
        margin-bottom: 2px;
      }
    }

    .desc,
    .pheno,
    .lineage {
      grid-column: 1 / 3;
    }
    .dominance,
    .potency {
      grid-column: 1 / 2;
    }

    .desc {
      grid-row: 1 / 2;
    }
    .pheno {
      grid-row: 2 / 3;
    }
    .lineage {
      grid-row: 3 / 4;
    }

    .dominance {
      grid-row: 4 / 5;
    }
    .potency {
      grid-row: 5 / 6;
    }
    .terpenes {
      grid-column: 2 / 3;
      grid-row: 4 / 6;
      border-left: 1px solid $med-gray;
      @media only screen and (min-width: 900px) {
        border: none;
      }
    }
    .desc,
    .pheno,
    .lineage,
    .dominance {
      border-bottom: 1px solid $med-gray;
      @media only screen and (min-width: 900px) {
        border: none;
      }
    }
    .dominance span,
    .potency span,
    .terpenes span {
      display: block;
      margin-bottom: 3px;
      @media only screen and (min-width: 900px) {
        margin-bottom: 0;
      }
    }
  }
}

.nug-fig {
  padding-top: 40px;
}

.faq-page {
  max-width: 768px;
  margin: auto;
}

.img-details {
  padding-top: 50px;
  .strain-art-wrapper {
    padding-bottom: 50px;
  }
  .strain-art-img {
    width: 100%;
    width: 90px;
    position: absolute;
    top: 163px;
    right: 66px;
  }
}

.strain-index-page,
.heritage-page,
.strain-details-pages,
.img-content {
  background: $black;
  color: $white;
}

.black_back {
  background: $black;
  color: $white;
}

.white_back {
  background: $white;
  color: $black;
}

.slice-wrapper {
  .slice-single {
    &.desk_half_w:nth-of-type(even) {
      border-top: 1px solid $dark-gray;
    }
  }
}

.products-list {
  padding: 1rem 2rem;
  .product {
    margin: 2rem auto;
  }

  .copy-wrapper {
    border-top: 1px solid $med-gray;
    h2 {
      font-family: 'Gotham Book', sans-serif;
      text-transform: uppercase;
      font-size: 1.6rem;
    }
    .extra-wrapper {
      padding: 2rem;
    }
  }
  .product-link {
    margin-top: 2rem;
    display: block;
  }
}

// NEW

.forms-list {
  .form-wrapper {
    .form {
      margin: 2rem auto;
      padding: 1rem 2rem;
    }

    .copy-wrapper {
      h2 {
        font-family: 'Gotham Book', sans-serif;
        text-transform: uppercase;
        font-size: 1.6rem;
      }
      .extra-wrapper {
        padding: 1rem;
        margin-top: 1rem;
        .description-name {
          margin-bottom: 0;
        }
        .description-copy {
          p {
            margin: 5px auto 12px;
          }
        }
      }
    }
    .shop-link {
      margin-top: 2rem;
      display: block;
    }
  }
  .form-wrapper:last-child .form {
    margin-bottom: 0;
    padding-bottom: 3rem;
  }
}

// END

@media only screen and (min-width: 768px) {
  .strains {
    padding-bottom: 65px;
  }
  .slice-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-flow: column;
    .slice-single {
      grid-column: 1 / 3;
      &.desk_half_w:nth-of-type(even) {
        grid-column: 1 / 2;
        border-top: none;
        border-right: 1px solid $dark-gray;
      }
      &.desk_half_w:nth-of-type(odd) {
        grid-column: 2 / 3;
      }
    }

    .slice-single.desk_full_w .img-content {
      .container {
        margin: auto;
        padding: 60px 40px;
      }
      .lazy-img-wrapper {
        max-width: 650px;
        margin: auto;
      }
      .sep-title h1,
      h2,
      h3 {
        max-width: 100%;
      }
      .copywrapper {
        max-width: 600px;
        margin: auto;
      }
    }
  }

  .cs-details {
    height: 320px;
    .cs-details-img {
      width: 130px;
    }
  }
  .cs-info {
    p {
      margin-top: 7px;
    }
    .cs-info-img {
      width: 95px;
    }
  }

  #jane-menu {
    margin-top: 70px;
  }
  .resp-grid-4col {
    grid-template-columns: repeat(auto-fit, minmax(140px, 260px));
  }
  .name {
    h3 {
      margin-bottom: 5px;
    }
  }
  .desk_half_w {
    .container {
      max-width: 600px;
      margin: auto;
    }
  }
  .desk_full_w {
    .container {
      max-width: 800px;
    }
  }

  .desk_full_w {
    .full-background-hero {
      .container {
        max-width: 1525px !important;
        width: 1525px;
        margin: auto;
      }
    }
  }

  .desk_full_w {
    .standard-hero {
      .container {
        max-width: 1100px;
        margin: auto;
      }
    }
  }

  .products-list {
    padding: 100px 2rem;
    .product {
      display: flex;
      max-width: 1450px;
      max-height: 615px;
      margin-bottom: 100px;
      overflow: hidden;
    }
    .product-img {
      max-width: 575px;

      img {
        object-position: center;
        width: inherit;
        height: 100%;
      }
    }

    .copy-wrapper {
      border-top: none;
      border-left: 1px solid $med-gray;

      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        font-size: 2rem;
        margin-bottom: 3rem;
      }
      .extra-wrapper {
        text-align: left;
        padding-left: 70px;
      }
    }
  }

  // START
  .forms-list {
    .form-wrapper {
      .form {
        display: flex;
        max-width: 1300px;
        margin: 3rem auto;
        padding: 2rem;
      }
      .form-img {
        height: 100%;
        width: 100%;
        max-width: 446px;
        max-height: 446px;
        img {
          object-fit: center;
          object-position: center;
        }
      }

      .copy-wrapper {
        border-top: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        h2 {
          font-size: 2rem;
          margin-bottom: 3rem;
        }
        .extra-wrapper {
          text-align: left;
          padding: 0 2rem 2rem 3rem;
          margin-top: 0;
        }
      }
    }
    .form-wrapper:last-child .form {
      margin-bottom: 0;
      padding-bottom: 6rem;
    }
  }
  // END
}

//STRAIN DETAILS PAGE END

@media only screen and (min-width: 900px) {
  .opp-blocks {
    display: grid;
    grid-template-columns: minmax(auto, 650px) 100px minmax(auto, 650px);
    grid-template-rows: 540px 160px 540px 160px auto;
    justify-content: center;
    padding: 40px 0;

    .container {
      justify-self: center;
    }

    .d-right {
      margin-left: 30px;
    }
    .d-left {
      margin-right: 30px;
    }

    .block-1 {
      grid-column: 1 / 2;
      grid-row: 1 / 4;
    }

    .line-r-1 {
      grid-column: 2 / 4;
      grid-row: 2 / 3;
    }

    .block-2 {
      grid-column: 3 / 4;
      grid-row: 3 / 6;
    }

    .line-l-1 {
      grid-column: 1 / 3;
      grid-row: 4 / 5;
      justify-self: end;
    }

    .block-3 {
      grid-column: 1 / 2;
      grid-row: 5 / 6;
    }

    img {
      width: 450px;
      height: 450px;
    }
  }

  .three-img-section,
  .img-grid-container {
    text-align: left;

    .three-col-grid {
      grid-gap: 3rem;
      padding-top: 30px;
    }
    .img-grid {
      padding-top: 30px;
      grid-gap: 2rem;
    }
  }

  //STRAIN DETAILS PAGE

  .strain-desk-grid {
    display: inline-grid;
    grid-template-columns: 1fr 1fr 1fr;
    border: 1px solid $med-gray;
    margin: auto;
    width: 100%;
    max-width: 1350px;
    position: static;

    .img-details {
      grid-column: 1 / 2;
      border-right: 1px solid $med-gray;
      border-top: none;
      border-left: none;
      position: relative;
      padding-top: 80px;

      .strain-art-img {
        left: 8px;
        top: 7px;
      }
    }
    .details {
      grid-column: 2 / 4;
      border: none;
      grid-template-columns: 50% 50%;
      grid-template-rows: minmax(auto, 170px) minmax(50px, auto) minmax(55px, auto) minmax(
          120px,
          auto
        );
      .detail-heading {
        margin-right: 5px;
      }
      .terpenes .detail-heading {
        margin-bottom: 5px;
      }
      .terpenes {
        text-align: left;
        li {
          margin-bottom: 3px;
        }
      }
      .pheno,
      .dominance,
      .terpenes {
        grid-column: 1 / 2;
      }
      .lineage,
      .potency,
      button {
        grid-column: 2 / 3;
      }

      .pheno,
      .lineage {
        grid-row: 2 / 3;
      }
      .dominance,
      .potency {
        grid-row: 3 / 4;
      }
      .terpenes,
      button {
        grid-row: 4 / 5;
      }
      .pheno,
      .dominance,
      .terpenes {
        border-right: 1px solid $med-gray;
      }

      .pheno,
      .lineage,
      .dominance,
      .potency,
      .desc {
        border-bottom: 1px solid $med-gray;
      }
    }
  }
  .strain-info-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &.terpenes {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    &.shop-now-button {
      justify-content: center;
    }
  }
  .strain-details {
    text-align: center;
    padding-top: 120px;
    .strain-wrapper {
      padding: 50px 20px 25px;
      position: relative;
    }

    .nug-img {
      width: auto;
      margin: auto;
      padding-top: 50px;
      top: 7px;
    }

    .details {
      .desc {
        text-align: left;
        padding: 30px;
      }
      > div:not(.shop-now-button),
      ul {
        padding-left: 30px;
      }
    }
  }
}

.espanol-page {
  height: 100vh;
  background: $black;
  .img-content {
    padding-top: 150px;
  }
}
