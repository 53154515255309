.squareContainer {
  position: relative;
}

.perfectSquare {
  position: relative;
  width: 50%;
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.squareContent {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 30% 70%;
  .name {
    grid-row: 1 / 2;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10px;
  }
  .squareImg {
    grid-row: 2 / 3;
  }
}

.squareHover {
  background-color: $black;
  border: 1px solid $white;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 50ms ease;
  .sqHoverImg {
    opacity: 0;
    transition: all 200 ease;
    transform: scale(0.6);
  }
}

@media only screen and (min-width: 768px) {
  .squareContent {
    grid-template-rows: 65px calc(100% - 65px);
  }
}

@media only screen and (min-width: 1200px) {
  .squareHover {
    &:hover,
    &:focus {
      opacity: 1;
    }
    &:hover .sqHoverImg {
      opacity: 1;
      transform: scale(0.8);
    }
  }
}
