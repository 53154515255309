// FULL BACKGROUND HERO
.full-background-hero {
  width: 100%;
  position: relative;
  width: 100%;
  height: 85vh;
  display: grid;
  grid-template-columns: 30px auto 30px;
  grid-template-rows: 190px minmax(190px, auto) auto;
  overflow: hidden;
  .hero-fig {
    grid-row: 1 / 4;
    grid-column: 1 / 4;
    height: inherit;
    img {
      height: inherit;
    }
  }
  .container {
    max-width: 1400px;
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
  .copy-wrapper {
    text-align: center;
    max-width: 600px;
  }
}

// STANDARD HERO
.standard-hero {
  padding-top: 90px;
  .container {
    max-width: 1088px;
    margin: auto;
  }
  figure img {
    height: 100%;
    max-height: 450px;
  }

  .copywrapper.altHeadingCopy {
    h1 {
      font-family: 'Gotham book', sans-serif;
      max-width: 100%;
      margin: 20px auto 0;
      &:after {
        background: none;
        height: 0;
      }
    }
  }
}

// FULL BACKGROUND HERO V2
.full-background-hero-v2 {
  background: $black;
  color: $white;
  padding-top: 120px;

  .copy-wrapper {
    padding: 40px 40px 30px 40px;
    margin: auto;
  }
}

@media only screen and (min-width: 768px) {
  .full-background-hero {
    margin-top: 60px;
    grid-template-columns: 60px auto 60px;
    .copy-wrapper {
      text-align: left;
    }
  }
  .standard-hero {
    padding-top: 60px;
    .copywrapper.altHeadingCopy {
      h1 {
        margin: 50px auto 0;
      }
    }
  }
  .full-background-hero-v2 {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    height: auto;
    .hero-fig,
    .cover-img {
      max-height: 1000px;
    }
    .copy-wrapper {
      padding: 80px 40px;
    }
  }
}
