button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  font-family: 'Gotham Medium', sans-serif;
}

button:disabled {
  cursor: default;
}

.shop-buttons {
  font-size: 0.9rem !important;
}

.black-plus {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 25px;
  width: 25px;
  background: $med-gray;
  color: $black;
}

.button-container {
  margin-top: 30px;
  a {
    display: inline-block;
    margin-bottom: 1.5rem;
  }
}

.back-button {
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 1px;
  color: $white;
  .arrow {
    width: 15px;
    position: relative;
    bottom: 15px;
  }
}

.floating-faq {
  bottom: 20px;
  position: fixed;
  right: 20px;
  z-index: 2;
}

.paginate-wrapper {
  display: flex;
  justify-content: space-around;
  max-width: 300px;
  margin: auto;
}

.paginate-btn {
  color: $white;
  text-transform: uppercase;
  display: flex;
  font-size: 0.75rem;
  cursor: pointer;
  span {
    margin-top: 3px;
  }
  .arrow-l {
    width: 10px;
    margin-right: 4px;
  }
  .arrow-r {
    width: 19px;
    margin-left: 4px;
  }
}

.paginate-btn.largeDesktop {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  &.prev-strain {
    left: -90px;
  }
  &.next-strain {
    right: -90px;
  }
  span {
    margin-top: 0px;
  }
  .arrow-l {
    width: 7px;
    margin-right: 6px;
  }
  .arrow-r {
    width: 12px;
    margin-left: 4px;
  }
}

@media only screen and (min-width: 900px) {
  .back-button {
    position: absolute;
    top: 15px;
    left: 10px;
    margin-top: 7px;
  }
  .black-plus {
    height: 40px;
    width: 40px;
    font-size: 1.5rem;
  }
}
